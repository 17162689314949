<template>
  <div>
    <form
      :action="downloadApi"
      method="post"
      class="formBox"
      target="_blank"
      enctype="multipart/form-data"
    >
      <el-row>
        <div class="form-group">
          <label class="formlabel">from com.aurora.component.excel</label>
          <input style="width:180px;margin-left: 5px;" name="file" type="file" />
        </div>
      </el-row>

      <el-row>
        <div class="form-group">
          <label class="formlabel" style="margin-left: 210px;margin-top:8px;">type</label>
          <el-select style="margin-left:8px;" v-model="clickIdVal" class="wid180">
            <el-option label="click_id" value="click_id"></el-option>
            <el-option label="gaid" value="gaid"></el-option>
          </el-select>
        </div>
      </el-row>

      <el-row>
        <div class="form-group">
          <label style="margin-left:120px; margin-top:10px;" class="formlabel"
            >click id cell name</label
          >
          <input style="margin-left:8px;" v-model="title" class="form-control wid180" />
        </div>
      </el-row>
      <el-row>
        <div class="form-group">
          <label style="margin-left:168px; margin-top:10px;" class="formlabel">query date</label>
          <el-date-picker
            v-model="reportDate"
            type="daterange"
            range-separator="to"
            start-placeholder="start date"
            end-placeholder="end date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </el-row>
      <el-row>
        <div class="form-group">
          <label style="margin-left:197px; margin-top:10px;" class="formlabel">Source</label>
          <group-select
            v-model="sourceIds"
            :data-source="sourcesList"
            multiple
            :loading="sourceLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          />
        </div>
      </el-row>
      <el-row>
        <div class="form-group">
          <label style="margin-left:197px; margin-top:10px;" class="formlabel">Affiliate</label>
          <group-select
            v-model="affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="请选择"
          />
        </div>
      </el-row>
      <el-row>
        <div class="form-group">
          <label style="margin-left:144px; margin-top:10px;" class="formlabel">query colums</label>
          <input
            style="margin-left:8px;"
            type="text"
            class="form-control wid180"
            v-model="columns"
          />
        </div>
      </el-row>

      <el-row>
        <el-col :lg="8" :offset="10">
          <button type="submit" class="btn btn-primary">Download Excel</button>
        </el-col>
      </el-row>
    </form>
  </div>
</template>

<script>
  import { myMixin } from '@/mixins/mixins.js';
  import { api } from 'api/billing/postback';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  import { mapActions, mapState } from 'vuex';

  export default {
    components: { GroupSelect },
    mixins: [myMixin],
    data() {
      return {
        clickIdVal: 'click_id',
        title: 'click_id',
        columns:
          'click_id|date|adjusted|affiliate_id|aff_sub|offer_id|original_url|redirect_url|gaid',
        reportDate: [],
        sourceIds: [],
        affiliateIds: [],
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      downloadApi() {
        let url = `${api.DOWNLOAD_BILL}?type=${this.clickIdVal}&columns=${this.columns}&title=${this.title}`;
        if (Array.isArray(this.reportDate) && this.reportDate.length === 2) {
          const [fromDate, toDate] = this.reportDate;
          url += `&fromDate=${fromDate}&toDate=${toDate}`;
        }
        if (Array.isArray(this.sourceIds)) {
          url += `&sourceIds=${this.sourceIds.join(',')}`;
        }
        if (Array.isArray(this.affiliateIds)) {
          url += `&affiliateIds=${this.affiliateIds.join(',')}`;
        }
        return url;
      },
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      ...mapActions('affiliate', ['getAffiliateList']),
    },
    mounted() {
      this.getAffiliateList();
      this.getSourceIdsList();
    },
  };
</script>

<style lang="scss" scoped>
  .formBox {
    margin-left: 20px;
    width: 700px;

    .form-group {
      margin-bottom: 10px;

      .formlabel {
        font-size: 16px;
        float: left;
        margin-right: 2px;
        margin-top: 4px;
      }

      .wid180 {
        width: 180px;
      }
    }

    .btn-primary {
      width: 120px;
      height: 34px;
      line-height: 34px;
      border-radius: 4px;
      background: #409eff;
      color: #fff;
      border-color: #409eff;
    }

    .form-control {
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      position: relative;
      font-size: 14px;
      display: inline-block;
      width: 100%;
    }
  }
</style>
