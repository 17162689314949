<template>
  <div>
    <el-form :inline="true">
      <el-row>
        <el-form-item label="ClickUrl" label-width="100px">
          <el-input v-model="clickUrl" placeholder="clickUrl here..."></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="parseReissueInfo">Parse</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="callbackReissue">Callback</el-button>
        </el-form-item>
      </el-row>
      <el-row style="margin-left: 10px">
        <el-form-item>
          <el-upload
            class="upload-demo"
            action=""
            accept=".csv,.txt,.xlsx,.xls"
            :file-list="fileList"
            :http-request="customRequest"
          >
            <el-button slot="trigger" size="small" type="primary">Select File</el-button>
            <el-button
              style="margin-left: 10px;"
              size="small"
              type="success"
              :disabled="fileList.length <= 0"
              @click="batchParseReissue"
            >
              Batch Parse
            </el-button>
            <el-button
              style="margin-left: 10px;"
              size="small"
              type="success"
              :disabled="fileList.length <= 0"
              @click="batchCallbackReissue"
            >
              Batch Callback
            </el-button>
          </el-upload>
        </el-form-item>
      </el-row>
    </el-form>
    <div style="margin-top:20px;" v-loading="loading">
      <!-- Batch  -->
      <template v-if="pageShowModel.isBatch">
        <el-row style="margin-left: -10px;">
          <el-col :span="24" :offset="1">
            <span style="font-size: 14px; font-weight: bold;">
              {{ pageShowModel.isParse ? 'Batch Parse Result' : 'Batch Callback Result' }}
            </span>
          </el-col>
          <el-col :span="22" :offset="1">
            <el-table :data="pageShowModel.isParse ? reissueListInfo : batchCallbackList" border>
              <el-table-column label="OfferId" prop="offerId" />
              <el-table-column label="Count" prop="count" />
            </el-table>
          </el-col>
        </el-row>
      </template>
      <!-- Single -->
      <div style="font-size: 14px;" v-else>
        <template v-if="pageShowModel.isParse">
          <el-row style="margin-left:-10px">
            <el-col :span="24" :offset="1">
              <span style="font-weight: bold;">Parse Result</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px; ">
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                SourceId:
                <span>{{ reissueInfo.sourceId }}</span>
              </p>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                OfferId:
                <span>{{ reissueInfo.offerId }}</span>
              </p>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                AffiliateId:
                <span>{{ reissueInfo.affiliateId }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row style="margin-top:10px;">
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                ClickTime:
                <span>{{ reissueInfo.clickTime }}</span>
              </p>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                AffiliateSub:
                <span>{{ reissueInfo.affiliateSub }}</span>
              </p>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row style="margin-top: 20px; margin-left: -10px;">
            <el-col :span="24" :offset="1">
              <span style="font-weight: bold;">CallBack Result:</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px;">
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                Code:
                <span>{{ callbackInfo.code }}</span>
              </p>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                Status:
                <span>{{ callbackInfo.success ? 'Success' : 'Failed' }}</span>
              </p>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <p>
                Description:
                <span>{{ callbackInfo.description }}</span>
              </p>
            </el-col>
          </el-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    parseReissue,
    batchParseReissue,
    callbackReissue,
    batchCallbackReissue,
  } from '@/api/billing/postback';

  export default {
    data() {
      return {
        pageShowModel: {
          isBatch: false,
          isParse: true,
        },
        clickUrl: '',
        reissueInfo: {
          affiliateId: '',
          sourceId: '',
          offerId: '',
          clickTime: '',
          affiliateSub: '',
        },
        reissueListInfo: [],
        callbackInfo: {
          code: '',
          success: false,
          description: '',
        },
        batchCallbackList: [],
        fileList: [],
        loading: false,
      };
    },
    mounted() {},
    methods: {
      async parseReissueInfo() {
        this.pageShowModel = {
          isBatch: false,
          isParse: true,
        };
        this.loading = true;
        try {
          const { result = {} } = await parseReissue({
            clickUrl: this.clickUrl,
          });

          this.reissueInfo = result;
        } catch (error) {
          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      },

      async batchParseReissue() {
        this.pageShowModel = {
          isBatch: true,
          isParse: true,
        };
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append('file', this.fileList[0]);
          const { result = [] } = await batchParseReissue(formData);
          this.reissueListInfo = result;
        } catch (error) {
          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      },
      async callbackReissue() {
        this.pageShowModel = {
          isBatch: false,
          isParse: false,
        };
        this.loading = true;
        try {
          const { result = {} } = await callbackReissue({
            clickUrl: this.clickUrl,
          });

          this.callbackInfo = result;
        } catch (error) {
          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      },
      async batchCallbackReissue() {
        this.pageShowModel = {
          isBatch: true,
          isParse: false,
        };
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append('file', this.fileList[0]);
          const { result = [] } = await batchCallbackReissue(formData);
          this.batchCallbackList = result;
        } catch (error) {
          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      },

      customRequest({ file }) {
        this.fileList = [file];
      },
    },
  };
</script>

<style></style>
