<template>
  <div>
    <el-form :inline="true" label-width="100px">
      <el-row>
        <label class="labelName">Offer(s)</label>
        <el-form-item label="OfferId(s)">
          <el-input
            v-model="offerIds"
            placeholder="offerIds: eg. 2206264 or 2206264,1567396"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="OfferName" label-width="170px">
          <el-input v-model="offerName" placeholder="OfferName: eg. Mobile Strike"></el-input>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-checkbox v-model="offerChecked"></el-checkbox>
        </el-form-item> -->
      </el-row>

      <el-row>
        <label class="labelName">Affiliate(s)</label>
        <el-form-item label="AffiliateId(s)">
          <group-select
            v-model="affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            clearable
            filterable
            placeholder="请选择"
            style="width:210px;"
          />
        </el-form-item>
      </el-row>
      <el-row>
        <label class="labelName">Source(s)</label>
        <el-form-item label="SourceId(s)">
          <group-select
            v-model="sourceIds"
            :data-source="sourcesList"
            multiple
            :loading="sourceLoading"
            clearable
            filterable
            placeholder="请选择"
            style="width:210px;"
          />
        </el-form-item>

        <!-- <el-form-item>
          <el-checkbox v-model="affiliateChecked"></el-checkbox>
        </el-form-item> -->
      </el-row>
      <el-row>
        <label class="labelName">Pkg(s)</label>
        <el-form-item label="Pkg(s)">
          <el-input
            v-model="pkgList"
            placeholder="eg: test.com,affilaite.com"
            style="width:210px;"
          />
        </el-form-item>

        <!-- <el-form-item>
          <el-checkbox v-model="affiliateChecked"></el-checkbox>
        </el-form-item> -->
      </el-row>
      <el-row>
        <label class="labelName" style="margin-right:20px;">TimeZone</label>
        <el-form-item>
          <el-select v-model="timezone" style="width:420px;">
            <el-option
              v-for="item in timezoneOption"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <!-- <el-row>
        <el-col :lg="6" :md="20">
          <label class="labelName">Interval</label>
          <el-form-item label="Month">
            <el-checkbox v-model="monthChecked"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Date">
            <el-checkbox v-model="dateChecked"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20">
          <el-form-item label="Hour">
            <el-checkbox v-model="hourChecked"></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <label class="labelName" style="margin-right:60px;">Date</label>
        <el-form-item>
          <el-date-picker
            v-model="startToEndDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            style="width:260px;"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-row>
      <el-row>
        <label class="labelName" style="margin-right:60px;">Aff click_id format</label>
        <el-form-item>
          <el-input
            v-model="affClickIdName"
            style="width:340px;"
            placeholder="pls input clickId's name in redirectUrl,eg: click_id"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :lg="3" :md="20" :offset="1">
          <el-form-item>
            <el-button type="primary" @click="downloadPostClick">Download Excel</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import qs from 'querystring';
  import optionData from '@/assets/js/optionData';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import dateUtils from '@/utils/dateutils.js';
  import { api } from 'api/billing/postback';
  import { myMixin } from '@/mixins/mixins.js';

  export default {
    components: {
      GroupSelect,
    },
    mixins: [myMixin],
    data() {
      return {
        offerIds: null,
        offerName: null,
        offerChecked: false,
        affiliateIds: null,
        affiliateChecked: true,
        sourceIds: null,
        timezone: '+00:00',
        timezoneOption: optionData.timezoneOption,
        // monthChecked: false,
        // dateChecked: true,
        // hourChecked: true,
        affClickIdName: null,
        startToEndDate: '',
        pkgList: '',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      downloadPostClick() {
        const param = {};
        if (this.offerIds) {
          param['offerIds'] = this.offerIds;
        }

        if (this.affiliateIds) {
          param['affiliateIds'] = this.affiliateIds.join(',');
        }
        if (this.affClickIdName) {
          param['affClickIdName'] = this.affClickIdName;
        }
        if (this.sourceIds) {
          param['sourceIds'] = this.sourceIds.join(',');
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        param.pkgList = this.pkgList;
        if (this.timezone) {
          param['timezone'] = this.timezone;
        }

        const strParam = qs.stringify(param);
        window.open(`${api.DOWNLOADE_CLICK_BILL}?${strParam}`, '_blank');
      },
    },
  };
</script>

<style></style>
