import request from 'utils/request';

export const api = {
  DOWNLOAD_BILL: '/api/postback/billing/downloadBill',
  DOWNLOADE_CLICK_BILL: '/api/postback/billing/downloadClickBill',
  OFFER_CAUSE: '/api/postback/billing/offer/cause',
  SEND_CONVERSION: '/api/postback/billing/sedConversion',

  PARSE_REISSUE: '/api/postback/billing/reissue/singleton',
  PARSE_REISSUE_BATCH: '/api/postback/billing/reissue/multiple',

  CALLBACK_REISSUE: '/api/postback/billing/reissue/singleton/issue',
  CALLBACK_REISSUE_BATCH: '/api/postback/billing/reissue/multiple/issue',
};

export function getOfferCause(offerId, affiliateId) {
  return request({
    url: api.OFFER_CAUSE,
    method: 'get',
    params: {
      offerId,
      affiliateId,
    },
  });
}

export function sendConversion(data) {
  return request({
    url: api.SEND_CONVERSION,
    method: 'post',
    data,
  });
}

export function parseReissue(data) {
  return request({
    url: api.PARSE_REISSUE,
    method: 'post',
    data,
  });
}

export function batchParseReissue(data) {
  return request({
    url: api.PARSE_REISSUE_BATCH,
    method: 'post',
    data,
  });
}

export function callbackReissue(data) {
  return request({
    url: api.CALLBACK_REISSUE,
    method: 'post',
    data,
  });
}

export function batchCallbackReissue(data) {
  return request({
    url: api.CALLBACK_REISSUE_BATCH,
    method: 'post',
    data,
  });
}
