<template>
  <div>
    <el-form :model="channel" :inline="true" label-width="100px">
      <el-row>
        <el-col :lg="12" :md="20">
          <label class="labelName">Give total</label>
          <el-form-item>
            <el-input v-model="channel.giveTotal" placeholder="postback give total"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12" :md="20">
          <label class="labelName">Offer</label>
          <el-form-item>
            <el-input
              v-model="channel.offerId"
              placeholder="offerId: eg. 2206264 or null"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <label class="labelName">Affiliate(s)</label>
        <el-form-item label="AffiliateId(s)">
          <el-select
            v-model="channel.affiliateId"
            :loading="affiliateLoading"
            filterable
            clearable
            placeholder="请选择"
            style="width:210px;"
          >
            <el-option-group
              v-for="group in affiliatesGroupList"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="item in group.children"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <label class="labelName" style="margin-right:20px;">TimeZone</label>
        <el-form-item>
          <el-select v-model="channel.timezone" style="width:420px;">
            <el-option
              v-for="item in timezoneChannelOption"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <label class="labelName" style="margin-right:60px;">Date</label>
        <el-form-item>
          <el-date-picker
            v-model="channelstartToEndDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            style="width:260px;"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :lg="3" :md="20" :offset="1">
          <el-form-item>
            <el-button type="primary" :disabled="disabledSendback" @click="sendPostbackClick"
              >Send Postback</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { sendConversion } from 'api/billing/postback';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';

  export default {
    mixins: [myMixin],

    data() {
      return {
        channel: {
          giveTotal: null,
          affiliateId: null,
          offerId: null,
          fromDate: null,
          toDate: null,
          timezone: '0',
        },
        timezoneChannelOption: [
          {
            label: '(GMT -12:00) Eniwetok, Kwajalein',
            value: '-12',
          },
          {
            label: '(GMT -11:00) Midway Island, Samoa',
            value: '-11',
          },
          {
            label: '(GMT -10:00) Hawaii',
            value: '-10',
          },
          {
            label: '(GMT -09:00) Alaska',
            value: '-9',
          },
          {
            label: '(GMT -08:00) Pacific Time(US & Canada)',
            value: '-8',
          },
          {
            label: '(GMT -07:00) Mountain Time(US & Canada)',
            value: '-7',
          },
          {
            label: '(GMT -06:00) Central Time(US & Canada), Mexico City',
            value: '-6',
          },
          {
            label: '(GMT -05:00) Eastern Time(US & Canada), Bogota, Lima',
            value: '-5',
          },
          {
            label: '(GMT -04:00) Atalantic Time(Canada), Caracas, La Paz',
            value: '-4',
          },
          {
            label: '(GMT -03:00) Brazil, Buenos Aires, Georgetown',
            value: '-3',
          },
          {
            label: '(GMT -02:00) Mid-Atlantic',
            value: '-2',
          },
          {
            label: '(GMT -01:00) Azores, Cape Verde Islands',
            value: '-1',
          },
          {
            label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
            value: '0',
          },
          {
            label: '(GMT +01:00) Brussels, Copenhagen, Madrid, Paris',
            value: '1',
          },
          {
            label: '(GMT +02:00) Kaliningrad, South Africa',
            value: '2',
          },
          {
            label: '(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg',
            value: '3',
          },
          {
            label: '(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi',
            value: '4',
          },
          {
            label: '(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
            value: '5',
          },
          {
            label: '(GMT +06:00) Almaty, Dhaka, Colombo',
            value: '6',
          },
          {
            label: '(GMT +07:00) Bangkok, Hanoi, Jakarta',
            value: '7',
          },
          {
            label: '(GMT +08:00) Beijing, Perth, Singapore, Hong Kong',
            value: '8',
          },
          {
            label: '(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
            value: '9',
          },
          {
            label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
            value: '10',
          },
          {
            label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
            value: '11',
          },
          {
            label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
            value: '12',
          },
        ],
        channelstartToEndDate: '',
        disabledSendback: false,
        startToEndDate: '',
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.channelstartToEndDate = [];
      this.channelstartToEndDate[0] = dateUtils.date2StrYMD(end);
      this.channelstartToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      sendPostbackClick() {
        let param = {
          ...this.channel,
        };
        if (param.affiliateId == null) {
          this.$message.error('affiliate not null');
          return;
        }

        param.affiliateId += '';

        if (this.channelstartToEndDate.length === 2) {
          let startDate = this.channelstartToEndDate[0];
          let endDate = this.channelstartToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        } else {
          this.$message.error('date not null');
          return;
        }

        if (param.timezone == null) {
          param.timezone = 0;
          return;
        }
        if (param.giveTotal == '') {
          this.$message.error('give total not null');
          return;
        }

        this.disabledSendback = true;
        sendConversion(param).then((response) => {
          this.disabledSendback = false;
          if (response.code == 200) {
            const result = response.result;
            this.$notify({
              title: 'Conversion Result',
              position: 'top-right',
              dangerouslyUseHTMLString: true,
              message: `<div><span style="margin-right:10px">Give total:</span>${result.realGiveTotal}</div>
                        <div style="max-width:330px;word-break: break-all;word-wrap: break-word;"><span style="margin-right:10px">Message:</span>${result.msg}</div>`,
              type: 'success',
              duration: 2000,
              offset: 50,
            });
          } else {
            this.$message.error('Error:' + response.message);
          }
        });
      },
    },
  };
</script>

<style></style>
