<template>
  <div>
    <el-form :model="cause" :inline="true">
      <el-form-item label="AffiliateId" label-width="100px">
        <el-select
          v-model="affiliate"
          :loading="affiliateLoading"
          clearable
          filterable
          placeholder="请选择"
          style="width:210px;"
        >
          <el-option-group
            v-for="group in affiliatesGroupList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="(item, index) in group.children"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="OfferId" label-width="60px">
        <el-input v-model="cause.offerId" placeholder="offerId here..."></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="showOfferCauseClick">Show</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-top:20px;">
      <div>
        <el-row>
          <el-col :lg="7" :md="20" :offset="1">
            <p class="trackOfferItem">
              Approval:
              <span>{{ approval }}</span>
            </p>
          </el-col>
          <el-col :lg="7" :md="20" :offset="1">
            <p class="trackOfferItem">
              Detail:
              <span>{{ detail }}</span>
            </p>
          </el-col>
          <el-col :lg="7" :md="20" :offset="1">
            <p class="trackOfferItem">
              Pkg:
              <span>{{ pkg }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top:20px;">
        <el-tree
          :data="allcauses"
          default-expand-all
          :render-content="renderContent"
          :props="defaultProps"
        ></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { getOfferCause } from 'api/billing/postback';

  export default {
    data() {
      return {
        cause: {
          code: null,
          offerId: null,
        },
        affiliate: null,
        approval: null,
        detail: null,
        pkg: null,
        allcauses: null,
        defaultProps: {
          children: 'nextCause',
          label: 'causeMsg',
        },
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      showOfferCauseClick() {
        getOfferCause(this.cause.offerId, this.affiliate).then((response) => {
          if (response.code === 200) {
            this.allcauses = [];
            this.causemsg = response.result;
            this.approval = response.result.approval;
            this.detail = response.result.detail;
            this.pkg = response.result.pkg;
            this.allcauses.push(response.result.cause);
            console.log(this.allcauses);
            this.familyTree(this.allcauses);
          }
        });
      },
      familyTree(arr) {
        let temp = [];
        for (let i in arr) {
          var item = arr[i];
          if (item.nextCause != undefined) {
            temp.push(item.nextCause);
            item.nextCause = temp;
            this.familyTree(temp);
          }
        }
      },
      renderContent(h, { data }) {
        return (
          <div>
            <div style="margin-right: 20px;">
              condition:<span>{data.condition}</span>
              <span>[{data.causeMsg}]</span>
            </div>
          </div>
        );
      },
    },
  };
</script>

<style></style>
